import { Key, useEffect, useState } from "react";
import ProjectionModelingTable from "./components/ProjectionModelingTable";
import i18n from "util/base/i18n";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { handleExportGridData } from "util/functions/handleExportGridData";
import NewProjection from "./newProjection/NewProjection";
import "./style.sass";
import { FormulaOperator } from "./newProjection/stepTwo/FormulaOperatorEnum";
import moment from "moment";
import { ListConditions } from "./newProjection/stepOne/IStepOne";
import { Icon } from "@iconify/react";
import { ColumnsType } from "antd/lib/table";
import { IImportModal } from "components/importExportMenu/IImportExportMenu";
import { Notification } from "components/notification/Notification";
import { CustomButtons } from "components/topButtons/ITopButtons";
import { TopButtons } from "components/topButtons/TopButtons";
import { useUserContext } from "context/UserContext";
import { ProfessionalCategoryTableData } from "module/budget/pages/professionalCategory/IProfessionalCategory";
import { Form } from "antd";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { FlexFieldFilterResponse, FlexFieldValueListBean, Options } from "util/types/types";
import { EventData, FormValues, IAccounting, ICostCenter, IFieldList, IFlexFieldValues, OriginData, TableData, IGroupList, IProjection } from "./IProjectionModeling";
import "./style.sass";
import MultipleSearch from "./components/MultipleSearch";
import { IExpressions } from "./newProjection/stepTwo/IStepTwo";
import { IProjectionGrouperData } from "../groupers/IProjectionGroupers";

export default function ProjectionModeling() {
	const [tableData, setTableData] = useState<IGroupList[]>([]);
	const [filterdTableData, setFilterdTableData] = useState<IGroupList[]>([]);
	const [isFetching, setIsFetching] = useState<boolean>(true);
	const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
	const [selectedRows, setSelectedRows] = useState<TableData[]>([]);
	const [isNewProjection, setIsNewProjection] = useState<boolean>(false);
	const [originData, setOriginData] = useState<OriginData[]>([]);
	const [grouperData, setGrouperData] = useState<IProjectionGrouperData[]>([]);
	const [eventData, setEventData] = useState<EventData[]>([]);
	const [costCenterList, setCostCenterList] = useState<IModalityFilter[]>([]);
	const [accountingList, setAccountingList] = useState<IModalityFilter[]>([]);
	const [isEditingProjection, setIsEditingProjection] = useState<boolean>(false);
	const [stepOneListConditions, setStepOneListConditions] = useState<ListConditions[]>([]);
	const [flexFieldList, setFlexFieldList] = useState<IModalityFilter[]>([]);
	const [flexFieldValues, setFlexFieldValues] = useState<IFlexFieldValues[]>([]);
	const [fieldList, setFieldList] = useState<IFieldList[]>([]);
	const [inputValue, setInputValue] = useState<number>(0);
	const [formula, setFormula] = useState([]);
	const [plainFormula, setPlainFormula] = useState<IExpressions[]>([]);
	const [isWhole, setIsWhole] = useState<boolean>(false);
	const [isFetchingValues, setIsFetchingValues] = useState<boolean>(false);
	const [isCalculatingBalance, setIsCalculatingBalance] = useState<boolean>(false);
	const [isInstallment, setIsInstallment] = useState<boolean>(false);
	const [ledgerList, setLedgerList] = useState<IModalityFilter[]>([]);
	const [grouperList, setGrouperList] = useState<IGroupList[]>([]);
	const [selectedEvent, setSelectedEvent] = useState<string>();
    const [projectionsOptions, setProjectionsOptions] = useState<Options[]>([]);
	const { userInfo } = useUserContext();

    const fetchValues: CustomButtons = {
        onClick: handleClickFetchValuesButton,
        icon: <Icon icon="mdi:receipt-text-clock-outline" />,
        className: "icon-copy",
        toolTip: i18n.t("fetch_values")
    }

    const calculateBalance: CustomButtons = {
        onClick: handleClickCalculateBalanceButton,
        icon: <Icon icon="material-symbols:calculate-outline" />,
        className: "icon-copy",
        disabled: isCalculatingBalance,
        toolTip: i18n.t("perform_balance_calculation")
    }

    const modelingProcess: CustomButtons = {
        className: "icon-copy",
        icon: <Icon icon="material-symbols:settings" />,
        disabled: isCalculatingBalance,
        dropDownItens: [
            { title: i18n.t("perform_balance_calculation"), onClick: handleClickCalculateBalanceButton },
            { title: i18n.t("fetch_values"), onClick: handleClickFetchValuesButton },
            { title: i18n.t("copy"), onClick: handleClickFutureProjection },
        ],
    }

    const [form] = Form.useForm<FormValues>();

    const importProps: IImportModal[] = [
        {
            importUrl: "/planning/projection/import/initial-balance-budget",
            templateUrl: "/planning/projection/import/initial-balance-budget/template?locale={locale}",
            type: 'excel',
            title: i18n.t("import_opening_balance")
        },
        {
            importUrl: "/planning/accomplished/transaction/value/import",
            templateUrl: "/planning/accomplished/transaction/value/template?locale={locale}&user={user}&client={client}",
            type: 'excel',
            title: i18n.t("import_accomplished_balance")
        }
    ];

    const tableColumns: ColumnsType<TableData> = [
        {
            title: i18n.t<string>("event"),
            dataIndex: "event",
            key: "event",
            align: "left",
            render: (value, { children = [] }) => {
                if (!children.length) return;
                return value;
            }
        },
        {
            title: i18n.t<string>("description"),
            dataIndex: "description",
            key: "description",
            align: "left",
        },
        {
            title: i18n.t("ledger_book"),
            dataIndex: "ledgerId",
            key: "ledgerId",
            render: (id: number) => {
                const label = ledgerList.find(({ value }) => value === id)?.label;
                return label;
            }
        },
        {
            title: i18n.t<string>("calculation_order"),
            dataIndex: "calculationOrder",
            key: "calculationOrder",
            align: "left",
        },
        {
            title: `${i18n.t<string>("origin")} (01)`,
            dataIndex: "originId",
            key: "originId",
            align: "left",
            render: (value) => {
                const option = originData.find(item => item?.value === value);
                return option?.label || "";
            }
        },
        {
            title: "Data de vigência",
            dataIndex: "endDate",
            key: "endDate",
            align: "center"
        }
    ];

    useEffect(() => {
        fetchTableData();
        loadFlexFieldValues();
    }, []);

    useEffect(() => {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/projection?organization={organization}`
        }, onLoadProjection.bind(this));
	}, [grouperData]);

	useEffect(() => {
		if (!isNewProjection) return;
		ServiceCaller.doRequest(
			{
				type: RequestType.GET,
				url: `/planning/projection/find-projections-excluding-id?id=${selectedRowKeys}`,
			}, onLoadProjections);
	}, [isNewProjection, selectedRowKeys])

    function loadFlexFieldValues() {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/flex-field-value/find-all-flexfield-without-security?flexFieldId=0`
        }, getFlexFieldValues.bind(this))
    }

    function getFlexFieldValues(data: FlexFieldValueListBean[]) {
        if (data.length === 0) {
            setFlexFieldValues([]);
            return;
        }

        const flexFieldValues: IFlexFieldValues[] = data.map((item) => {
            const label: string = `${item.externalCode} - ${item.description}`;
            return {
                value: item.id,
                flexFieldId: item.flexFieldId,
                label,
            };
        });

        setFlexFieldValues(flexFieldValues);
    }

	function fetchTableData() {
		setIsFetching(true)
		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/planning/projection/grouper`
		}, onLoadGrouper.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/origin`
        }, onLoadOrigin.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/origin/find-events`
        }, onLoadEvent.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/planning/management-cost-center",
        }, onLoadCostCenter.bind(this));

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/planning/management-account-plan-hierarchy/get-accounting-accounts",
        }, onLoadAccounting);

        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: "/monolith/ledger?client={client}",
		}, onLoadLedger);
    }

    const onLoadProjections = (data: IProjection[]) => {
        if (!data) {
            return;
        }

        const updatedSelect = Object.values<IProjection>(data).map((projection: IProjection) => ({
            value: projection.id,
            label: projection.description,
        }));
        setProjectionsOptions(updatedSelect);
    };

    function onLoadLedger(data: ProfessionalCategoryTableData[]) {
        const newLedgerList: IModalityFilter[] = data.map(item => {
            const label: string = `${item.externalCode} - ${item.name}`;
            return ({
                value: item.id,
                label
            })
        })

        setLedgerList(newLedgerList);
    }

    function onLoadCostCenter(data: ICostCenter[]) {
        const costCenters: IModalityFilter[] = data.map(item => {
            const label: string = `${item.externalCode} - ${item.description}`;
            return ({
                value: item.id,
                label
            })
        })
        setCostCenterList(costCenters);
    }

    function onLoadAccounting(data: IAccounting[]) {
        const accoutings: IModalityFilter[] = data.map(item => {
            const label: string = `${item.externalCode} - ${item.description}`;
            return ({
                value: item.id,
                label
            })
        }).sort((a, b) => a.label.localeCompare(b.label));
        setAccountingList(accoutings);
    }


    function onLoadProjection(data: Record<string, any>) {
        const eventKeys = Object.keys(data);
        if (eventKeys.length === 0 || grouperData.length === 0) {
            setTableData([]);
            setIsFetching(false);
            return;
        }

        const updateTableData: IGroupList[] = eventKeys.map((key) => {
            const grouperList: string[] = Object.keys(data[key]);

			const secondLevel: TableData[] = grouperList.map(grouper => {
				const children: TableData[] = data[key][grouper].map((res: any) => ({
					id: res.id,
					key: res.id,
					description: res.description,
					event: res.event,
					originId: res.originId,
                    formula: res.formula,
                    plainFormula: res.plainFormula,
					calculationOrder: res.order,
					startDate: res.effectiveStartDate,
					endDate: res.effectiveEndDate,
					currencyId: res.currencyId,
					percentualBase: res.percentualBase,
					cashCreditAccountingAccountId: res.cashCreditAccountingAccountId,
					cashDebitAccountingAccountId: res.cashDebitAccountingAccountId,
					cashCreditCostCenterId: res.cashCreditCostCenterId,
					cashDebitCostCenterId: res.cashDebitCostCenterId,
					accrualCreditAccountingAccountId: res.accrualCreditAccountingAccountId,
					accrualDebitAccountingAccountId: res.accrualDebitAccountingAccountId,
					accrualCreditCostCenterId: res.accrualCreditCostCenterId,
					accrualDebitCostCenterId: res.accrualDebitCostCenterId,
					scheduleDays: res.scheduleDays,
					schedulePayment: res.schedulePayment,
					flexFieldsConditions: res.flexFieldsConditions,
					ledgerId: res.ledgerId,
					grouperId: res.grouperId,
					projectionCopyType: res.projectionCopyType,
					indexerId: res.indexerId,
					indexerType: res.indexerType
				}));

				const grouperValue = Number(grouper);
				const keyGroup = grouperData.find((g: IProjectionGrouperData) => g.id === grouperValue)?.description || 'Unknown';

                return {
                    event: keyGroup,
                    key: Math.floor(Math.random() * 10000) + 1,
                    children: children,
                    value: grouperValue
                };
            });
            secondLevel.sort((a, b) => a.event.localeCompare(b.event));
            const randomKey = Math.floor(Math.random() * 10000) + 1;
            return {
                event: i18n.t("modules." + key.toLowerCase().replace("_", ".")),
                key: randomKey,
                children: secondLevel,
                value: randomKey
            };
        });

        setFilterdTableData(updateTableData);
        setTableData(updateTableData);
        setIsFetching(false);
    }

	function onLoadGrouper(data: IProjectionGrouperData[]) {
		if (data.length === 0) {
			setGrouperData([]);
			setIsFetching(false);
			return;
		};
		setGrouperData(data);
		setIsFetching(false);

    }

    function onLoadOrigin(data) {
        if (data.length === 0) {
            setOriginData([]);
            setIsFetching(false);
            return;
        };
        const origin = data.map(res => {
            return {
                value: res.id,
                label: res.description,
                event: res.event
            };
        })

        setOriginData(origin);
        setIsFetching(false);
    }

    function onLoadEvent(data) {
        if (data.length === 0) {
            setEventData([]);
            setIsFetching(false);
            return;
        };
        const origin = Object.keys(data).map(key => {
            const label: string = i18n.t(`modules.${data[key].toLowerCase().replace("_", ".")}`);
            return ({ value: key, label })
        });

        setEventData(origin);
        setIsFetching(false);
    }

    function handleNew() {
        setIsEditingProjection(false);
        setIsNewProjection(true);
    }

    function compareIds(editData, selectedRows) {
        const result = [];
        function searchIds(data, selectedIds) {
            data.forEach(item => {
                if (item.id && selectedIds.includes(item.id)) {
                    result.push(item);
                }
                if (item.children && item.children.length > 0) {
                    searchIds(item.children, selectedIds);
                }
            });
        }

        const selectedIds = selectedRows.map(row => (typeof row === 'object' ? row.id : row));

        searchIds(editData, selectedIds);

        return result;
    }

    const matchedItems: TableData[] = compareIds(tableData, selectedRows);

    function handleEdit() {
        if (matchedItems.length > 0) {
            const matchedItem = matchedItems[0];

			form.setFieldsValue({
				description: matchedItem.description,
				event: matchedItem.event,
				origin: matchedItem.originId,
				calculationOrder: matchedItem.calculationOrder,
				initialDate: moment(matchedItem.startDate),
				endDate: moment(matchedItem.endDate),
				currency: matchedItem.currencyId,
				base: matchedItem.percentualBase,
				cashCreditAcc: matchedItem.cashCreditAccountingAccountId,
				cashDebitAcc: matchedItem.cashDebitAccountingAccountId,
				cashCreditCc: matchedItem.cashCreditCostCenterId,
				cashDebitCc: matchedItem.cashDebitCostCenterId,
				competenceCreditAcc: matchedItem.accrualCreditAccountingAccountId,
				competenceDebitAcc: matchedItem.accrualDebitAccountingAccountId,
				competenceCreditCc: matchedItem.accrualCreditCostCenterId,
				competenceDebitCc: matchedItem.accrualDebitCostCenterId,
				boxCronogramWhole: matchedItem.schedulePayment === "WHOLE",
				boxCronogramInstallment: matchedItem.schedulePayment === "INSTALLMENT",
				ledgerId: matchedItem.ledgerId,
				grouper: matchedItem.grouperId,
				projectionCopyType: matchedItem.projectionCopyType,
				indexerId: matchedItem.indexerId,
				indexerType: matchedItem.indexerType
			});

            setSelectedEvent(matchedItem.event);
            loadFields(matchedItem.originId, matchedItem.plainFormula);
            loadFlexFields(matchedItem.event);
            formatConditions(matchedItem.flexFieldsConditions);
            setInputValue(matchedItem.scheduleDays);
            setIsWhole(matchedItem.schedulePayment === "WHOLE");
            setIsInstallment(matchedItem.schedulePayment === "INSTALLMENT");

            setIsEditingProjection(true);
            setIsNewProjection(true);
        }
    }

    function handleClickFetchValuesButton() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setIsFetchingValues(true);

        const data = {
            scenario: userInfo.selection.scenarioId,
            projections: JSON.stringify(selectedRowKeys)
        }

        Notification({
            type: "info",
            message: i18n.t("find_values.started"),
        });
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: `/planning/projection/create-values-by-projections?projections=${selectedRowKeys.toString()}&scenario={scenario}&client={client}&user={user}`,
            params: data
        }, (data) => {
            if (data) {
                Notification({
                    type: data.type,
                    message: i18n.t(data.message)
                });
            }
            setIsFetchingValues(false);
        });
    }

    function handleClickCalculateBalanceButton() {
        setIsCalculatingBalance(true);

        const data = {
            scenario: userInfo.selection.scenarioId,
            organization: userInfo.selection.organizationId
        }

        Notification({
            type: "info",
            message: i18n.t("calculation.started"),
        });
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: "/planning/projection/create-planning-values?user={user}&client={client}&scenario={scenario}&organization={organization}",
            params: data
        }, (data: any) => {
            Notification({
                type: data.type,
                message: i18n.t(data.message)
            });

            setIsCalculatingBalance(false);
        }
        );
    }

    function handleClickFutureProjection() {

        const data = {
            scenario: userInfo.selection.scenarioId,
            locale: userInfo.localeId,
            client: userInfo.clientId,
            user: userInfo.id,
            organization: userInfo.selection.organizationId
        }

        Notification({
            type: "info",
            message: i18n.t("copy_started"),
        });
        ServiceCaller.doRequest({
            type: RequestType.POST,
            url: "/planning/planning-balance/copy-values-long-projection",
            params: data
        }, (data: any) => {
            Notification({
                type: data.type,
                message: i18n.t(data.message)
            });
        });
    }

    function formatConditions(data) {
        const formattedConditions = !data ? [] : data.map(item => {
            return ({
                id: item.id,
                levels: item.flexFieldId,
                levelsValue: item.flexFieldValueId,
                operation: item.condition
            })
        })

        setStepOneListConditions(formattedConditions);
    }

    function unformatFormula(formattedFormula: string, isAfterLoadFields: boolean = false, fields: IFieldList[]): { type: string; content: string }[] {
        let formulaUnformatted = [];

        formattedFormula.split(' ').forEach(item => {
            let fieldId: string = i18n.t<string>(`${item.toLowerCase().replaceAll("$", "").replaceAll("{", "").replaceAll("}", "").replaceAll(".", "_")}`);

            switch (item) {
                case "+":
                    formulaUnformatted.push({
                        type: "operator", content: "PLUS"
                    });
                    break;
                case "-":
                    formulaUnformatted.push({
                        type: "operator", content: "LESS"
                    });
                    break;
                case "*":
                    formulaUnformatted.push({
                        type: "operator", content: "MULTIPLY"
                    });
                    break;
                case "/":
                    formulaUnformatted.push({
                        type: "operator", content: "DIVIDER"
                    });
                    break;
                case "(":
                    formulaUnformatted.push({
                        type: "operator", content: "LEFT_PARENTHESIS"
                    });
                    break;
                case ")":
                    formulaUnformatted.push({
                        type: "operator", content: "RIGHT_PARENTHESIS"
                    });
                    break;
                default:
                    const type = selectedRows[0].event === "REVENUE" ? (/\d+%/.test(item) ? "percentage" : (!isNaN(parseInt(item)) ? "value" : "field")) : "field";

                    formulaUnformatted.push({
                        type: type,
                        content: {
                            id: item,
                            type: type,
                            name: isAfterLoadFields && selectedRows[0].event === "REVENUE" ? fields.find(field => field.value.id === fieldId)?.value.name : fieldId
                        }
                    });
                    break;
            }
        });

        return formulaUnformatted;
    }

    function loadFlexFields(originSelected) {
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/budget-base/flex-field/find-all-by-module?module=${originSelected}`
        }, getFlexFields.bind(this))
    }

    function loadFields(originId: number, formula: string): void {
        ServiceCaller.doRequest(
            {
                type: RequestType.GET,
                url: `/planning/projection/get-fields?origin=${originId}`,
            },
            (data: IFieldList[]) => {
                if (data.length === 0) {
                    setFieldList([]);
                }
                const fields: IFieldList[] = Object.keys(data).map((key) => ({
                    value: {
                        id: key,
                        type: "field",
                        name: i18n.t<string>(`${data[key].toLowerCase()}`),
                    },
                    label: i18n.t(`${data[key]}`),
                }));

                setFieldList(fields);
                setFormula(unformatFormula(formula, true, fields));
            }
        );
    }

    function getFlexFields(data: FlexFieldFilterResponse[]) {
        if (data.length === 0) {
            setFlexFieldList([]);
            return;
        }

        const flexFields: IModalityFilter[] = data.map(item => {
            const label: string = `${item.externalCode} - ${item.description}`;
            return {
                value: item.id,
                label
            }
        })

        setFlexFieldList(flexFields);
    }

    function onSelectRowChange(keys: Key[], rows: TableData[]) {
        setSelectedRows(rows);
        setSelectedRowKeys(keys);
    };

    function handleDelete() {
        setIsFetching(true)
        ServiceCaller.doRequest({
            type: RequestType.DELETE,
            url: `/planning/projection?ids=${selectedRowKeys.toString()}`,
        }, onDeleteProjection.bind(this), onRequestError.bind(this));
        setSelectedRowKeys([]);
        setSelectedRows([]);
    }

    function onDeleteProjection(data) {
        if (data) {
            Notification({
                type: "success",
                message: i18n.t("successfully_deleted"),
            });
        }
        ServiceCaller.doRequest({
            type: RequestType.GET,
            url: `/planning/projection?organization={organization}`,
        }, onLoadProjection.bind(this));
    };

    function handleSaveProjection() {
        setIsFetching(true);
        const formValues = form.getFieldsValue();

        const conditions = stepOneListConditions.map((item) => {
            return {
                id: item.id,
                flexFieldId: item.levels,
                flexFieldValueId: item.levelsValue,
                condition: item.operation
            }
        });

		const data = {
            id: isEditingProjection ? selectedRowKeys[0] : null,
            organizationId: userInfo.selection.organizationId,
            description: formValues.description,
            event: formValues.event,
            originId: formValues.origin,
            formula: formatFormula(),
            plainFormula: formatPlainFormula(formValues.event),
            order: formValues.calculationOrder,
            effectiveStartDate: formValues.initialDate.format("YYYY-MM-DD"),
            effectiveEndDate: formValues.endDate.format("YYYY-MM-DD"),
            currencyId: formValues.currency,
            percentualBase: formValues.base,
            cashCreditAccountingAccountId: formValues.cashCreditAcc,
            cashDebitAccountingAccountId: formValues.cashDebitAcc,
            cashCreditCostCenterId: formValues.cashCreditCc,
            cashDebitCostCenterId: formValues.cashDebitCc,
            accrualCreditAccountingAccountId: formValues.competenceCreditAcc,
            accrualDebitAccountingAccountId: formValues.competenceDebitAcc,
            accrualCreditCostCenterId: formValues.competenceCreditCc,
            accrualDebitCostCenterId: formValues.competenceDebitCc,
            scheduleDays: inputValue,
            schedulePayment: isWhole === true ? "WHOLE" : "INSTALLMENT",
            flexFieldsConditions: conditions,
            ledgerId: formValues.ledgerId,
            grouperId: formValues.grouper,
            projectionCopyType: formValues.projectionCopyType,
            indexerId: formValues.indexerId,
            indexerType: formValues.indexerType,
        };

        ServiceCaller.doRequest({
            type: isEditingProjection ? RequestType.PUT : RequestType.POST,
            url: `/planning/projection?organization={organization}`,
            params: data
        }, onSaveProjection.bind(this), onRequestError.bind(this));
    }

    function formatFormula() {
        const expressions = [];
        
        formula.forEach((item, index) => {
            let fieldType = "STATIC_FIELD";
            let operator = null;
            let staticField = null;
            let value = null;
            
            switch (item.type) {
                case "field":
                    fieldType = "STATIC_FIELD";
                    staticField = item.content.id;
                    break;
                case "operator":
                    fieldType = "OPERATOR";
                    operator = FormulaOperator[item.content];
                    break;
                case "value":
                    fieldType = "ABSOLUTE_VALUE";

                    value = parseFloat(item.content.id);
                    break;
                case "percentage":
                    fieldType = "PERCENTAGE_VALUE";
                    value = parseFloat(item.content.id);
                    break;
            }

            const matchingPlainItem = plainFormula.find((plainItem) => plainItem.position === index);
            const expression = {
                position: index,
                fieldType: fieldType,
                operator: operator,
                staticField: staticField,
                modularFieldId: matchingPlainItem?.modularFieldId || null,
                projectionId: matchingPlainItem?.projectionId || null,
                managementAccountingAccountId: matchingPlainItem?.managementAccountingAccountId || null,
                managementCostCenterId: matchingPlainItem?.managementCostCenterId || null,
                period: matchingPlainItem?.period,
                rate: matchingPlainItem?.rate || null,
                value: value || matchingPlainItem?.value || null,
            };
            expressions.push(expression);
        });
        const formulaBean = {
            expressions: expressions,
        };
        return formulaBean;
    }

    const formatPlainFormula = (event: string): string => {
        let formattedFormula = [];
        formula.forEach((item) => {
            switch (item.type) {
                case "field":
                    const { id: contentId } = item.content;
                    const id = event === "REVENUE" && !/^\$\{\d+\}$/.test(contentId) ? `\${${contentId}}` : contentId;
                    formattedFormula.push(id);
                    break;
                case "operator":
                    formattedFormula.push(FormulaOperator[item.content]);
                    break;
                case "value":
                    const getValue = item?.informedValue ? item.informedValue : item.content.id;
                    formattedFormula.push(getValue.toString());
                    break;
                case "percentage":
                    const getPercentage = item?.informedValue
                        ? item.informedValue.toString() + FormulaOperator.PERCENTAGE
                        : item.content.id.toString();
                    formattedFormula.push(getPercentage);
                    break;
            }
        });
        return formattedFormula.join(" ");
    };

	function onSaveProjection(data) {
		if (data) {
			Notification({
				type: "success",
				message: i18n.t("successfully_saved"),
			});

			ServiceCaller.doRequest({
				type: RequestType.GET,
				url: `/planning/projection?organization={organization}`,
			}, onLoadProjection.bind(this));
		}

		form.resetFields();
		setStepOneListConditions([]);
		setFormula([]);
		setIsNewProjection(false);
		setIsEditingProjection(false);
		setIsFetching(false);
	}

	function onRequestError() {
		Notification({
			type: "warning",
			message: i18n.t("error"),
		});
		setIsFetching(false)
	}

    function onCancel() {
        setSelectedRowKeys([]);
        setSelectedRows([]);
        setStepOneListConditions([]);
        setFormula([]);
        setIsEditingProjection(false);
        setIsNewProjection(false);
        form.resetFields();
    }
    return (
        <>
            {!isNewProjection ? (
                <div>
                    <div className="page-title-content">
                        <h1>{i18n.t("projection_modeling")}</h1>
                    </div>
                    <div id="top-buttons-projections">
                        <TopButtons
                            mainButtonTitle={i18n.t("new_projection")}
                            handleNew={handleNew}
                            handleEdit={handleEdit}
                            handleDelete={handleDelete}
                            isEditable={
                                selectedRows.length === 1 &&
                                !matchedItems.find(({ id }) => id === selectedRowKeys[0])?.children?.length
                            }
                            isDeletable={
                                selectedRows.length > 0 &&
                                !tableData.find(({ id }) => id === selectedRowKeys[0])?.children?.length
                            }
                            customButtons={[modelingProcess]}
                            importExportOptions={{
                                exportGridData: () =>
                                    handleExportGridData(tableData, tableColumns, i18n.t("collaborators_name")),
                                importProps: importProps,
                            }}
                        />
                        <MultipleSearch
                            tableData={tableData}
                            setTableData={setFilterdTableData}
                            options={[
                                { i18nString: "description", description: "description" },
                                { i18nString: "event", description: "event" },
                            ]}
                        />
                    </div>
                    <main>
                        <ProjectionModelingTable
                            isFetching={isFetching}
                            tableData={filterdTableData}
                            selectedRowKeys={selectedRowKeys}
                            onChange={onSelectRowChange}
                            columns={tableColumns}
                        />
                    </main>
                </div>
            ) : (
                <NewProjection
                    costCenterList={costCenterList}
                    accountingList={accountingList}
                    originData={originData}
                    grouperData={grouperData}
                    eventData={eventData}
                    onCancel={onCancel}
                    stepOneListConditions={stepOneListConditions}
                    setStepOneListConditions={setStepOneListConditions}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                    form={form}
                    formula={formula}
                    plainFormula={plainFormula}
                    setFormula={setFormula}
                    setPlainFormula={setPlainFormula}
                    isEditingProjection={isEditingProjection}
                    handleSaveProjection={handleSaveProjection}
                    isWhole={isWhole}
                    setIsWhole={setIsWhole}
                    isInstallment={isInstallment}
                    setIsInstallment={setIsInstallment}
                    loadFlexFields={loadFlexFields}
                    flexFieldList={flexFieldList}
                    flexFieldValues={flexFieldValues}
                    fieldList={fieldList}
                    setFieldList={setFieldList}
                    ledgerList={ledgerList}
                    grouperList={grouperList}
                    setGrouperList={setGrouperList}
                    fetchTableData={fetchTableData}
                    projectionsOptions={projectionsOptions}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                />
            )}
        </>
    );
}
