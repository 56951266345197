import { Checkbox, Col, DatePicker, Form, Layout, Row, Select, Table } from "antd";
import i18n, { languages } from "util/base/i18n";
import { selectProps } from "util/props/props";
import FormulaContent from "./content/FormulaContent";
import { ColumnsType } from "antd/lib/table";
import { Icon } from "@iconify/react";
import { useEffect, useMemo, useState } from "react";
import Search from "antd/lib/input/Search";
import { ServiceCaller } from "util/service/ServiceCaller";
import { RequestType } from "util/service/IServiceCaller";
import { StepTwoProps } from "./IStepTwo";
import { ErrorRequest, ICurrency, Options } from "util/types/types";
import { handleErrorRequest } from "util/functions/handleErrorRequest";
import { FieldProps, ICopyType, IPeriodColumn, IPeriodValue, PlanningEventMap } from "../../IProjectionModeling";
import { GenericSelection } from "module/budget/pages/flexField/IFlexField";
import { IndexerType, IProjectionGrouperData } from "module/plan/pages/groupers/IProjectionGroupers";
import { IModalityFilter } from "module/budget/pages/revenue/reportView/components/Filters/IFilters";
import { IndexerResponse } from "module/budget/pages/budgetProjection/IBudgetProjection";

export default function StepTwo({
    form,
    selectedEvent,
    formula,
    projectionsOptions,
    accountingList,
    setFormula,
    plainFormula,
    setPlainFormula,
    currentStep,
    isEditingProjection = false,
    setPeriodColumns,
    setPeriodValues,
    fieldList,
	setFieldList,
	grouperData = [],
    originSelected
}: StepTwoProps) {
    const [searchContent, setSearchContent] = useState("");
    const [allCurrencies, setAllCurrencies] = useState<Options[]>([]);
	const [currencyDefault, setCurrencyDefault] = useState<Options>();
	const [indexerList, setIndexerList] = useState<IModalityFilter[]>([]);
	const indexerTypeList: GenericSelection[] = [
		{
			label: i18n.t("projection_grouper.none"),
			value: IndexerType.NONE
		},
		{
			label: i18n.t("projection_grouper.simple"),
			value: IndexerType.SIMPLE
		},
		{
			label: i18n.t("projection_grouper.accumulated"),
			value: IndexerType.ACCUMULATED
		},
	];
    const filteredData = fieldList.filter(item => item.label.toLowerCase().includes(searchContent));

    const initialDate = Form.useWatch("initialDate", form);
	const endDate: moment.Moment = Form.useWatch("endDate", form);
	const grouper: number = Number(Form.useWatch("grouper", form));
	const isIndexerGrouper: boolean = Form.useWatch("isIndexerGrouper", form);

	const copyTypeOptions: GenericSelection[] = [
		{
			label: i18n.t("simple_copy"),
			value: ICopyType.LAST_YEAR_ENTIRE_COPY
		},
		{
			label: i18n.t("final_balance_copy"),
			value: ICopyType.LAST_MONTH_COPY
		},
	]

    useEffect(() => {
		if (!grouperData.length || !grouper) return;
		const { indexerType, indexerId } = grouperData.find(({ id }) => id === grouper);

		if (isEditingProjection) {
			const isSameIndexerType: boolean = form.getFieldValue("indexerType") === indexerType;
			const isSameIndexerId: boolean = form.getFieldValue("indexerId") === indexerId;
			form.setFieldValue("isIndexerGrouper", (isSameIndexerId && isSameIndexerType));
			return;
		}

		form.setFieldsValue({
			indexerId,
			indexerType
		})
	}, [grouperData, grouper])

    useEffect(() => {
        if (originSelected) {
            ServiceCaller.doRequest(
                {
                    type: RequestType.GET,
                    url: `/planning/projection/get-fields?origin=${originSelected}`,
                },
                onLoadFields.bind(this)
            );
        }
    }, [originSelected]);

    useEffect(() => {
        if (allCurrencies.length > 0) {
            const currencyId: number = form.getFieldValue("currency");
            setCurrencyDefault(allCurrencies.filter(item => item.value == currencyId)[0]);
        }
    }, [allCurrencies]);

    useEffect(() => {
		loadAllCurrencies();

		ServiceCaller.doRequest({
			type: RequestType.GET,
			url: `/budget-base/indexer`,
		}, (data: IndexerResponse[]) => {
			const newIndexer: IModalityFilter[] = data.map(({
				description,
				externalCode,
				id }) => {
				return {
					label: `${externalCode} - ${description}`,
					value: id
				}
			})

			setIndexerList(newIndexer);
		})
    }, []);

    function loadAllCurrencies() {
        ServiceCaller.doRequest(
            {
                url: `/budget-base/currency-conversion/find-all-with-description?locale={locale}`,
                type: RequestType.GET,
            },
            (data: ICurrency[]) => {
                setAllCurrencies(data.map(currency => {
                    return {
                        label: currency.name,
                        value: currency.id
                    };
                }));
            },
            (err: ErrorRequest) => {
                handleErrorRequest(err);
            }
        );
    }

    function onLoadFields(data) {
        if (data.length === 0) {
            setFieldList([]);
        }
        const fields: FieldProps[] = Object.keys(data).map(key => {
            const label = i18n.t(`${data[key].toLowerCase().replace(".", "_")}`);
            return {
                value: {
                    id: key,
                    type: "field",
                    name: i18n.t(`${data[key].replace(".", "_")}`)
                },
                label: label
            };
        });

        setFieldList(fields);
    }

    const columns: ColumnsType = [
        {
            title: i18n.t<string>("origin"),
            dataIndex: "label",
        },
        {
            title: i18n.t<string>("button"),
            width: 100,
            align: "center",
            render: (item) => {
                return (
                    <Icon icon="ep:arrow-right-bold" onClick={() => addFieldToFormula(item)} style={{ color: "#d7d7d7" }} />
                )
            }
        }
    ]

    function addFieldToFormula(field) {
        if (formula.length === 0) {
            setFormula([
                ...formula,
                {
                    type: "field",
                    content: field.value,
                },
            ]);
            return;
        }

        if (formula[formula.length - 1].type !== "field") {
            setFormula([
                ...formula,
                {
                    type: "field",
                    content: field.value,
                },
            ]);
        }
    }

    function onSearch(e) {
        setSearchContent(e.target.value.toLowerCase());
    }

    useEffect(() => {
        if (endDate && initialDate) {
            const difference = endDate.month() - initialDate.month();
            const values: IPeriodValue = {};
            const columns: IPeriodColumn[] = [];

            const extraPeriods: number = 6;
            for (let i = 0; i <= difference + extraPeriods; i++) {
                columns.push({
                    title: `Periodo ${i + 1}`,
                    dataIndex: `period_${i + 1}_value`,
                    key: i,
                });
                values[`period_${i + 1}_value`] = "0,00";
            }

            setPeriodValues([values]);
            setPeriodColumns(columns);
        }
    }, [endDate, initialDate]);

    return (
        <div id="step-two-new-projection" style={{ display: currentStep != 1 ? "none" : "flex" }}>
            <div className="step-two-form-content">
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                >
                    <div className="form-container">
                        <Row gutter={20}>
							<Col span={24}>
								<Row>
									<h4 className="gs-tag blue">{"Origem (02)"}</h4>
								</Row>
                                <Table
                                    className={"table-projection"}
                                    columns={columns}
                                    dataSource={filteredData}
                                    pagination={{ defaultPageSize: 10 }}
                                    scroll={{ y: 240 }}
                                    showHeader={false}
                                    bordered
                                    title={() => {
                                        return (
                                            <Search placeholder="Origem" onChange={onSearch} />
                                        )
                                    }}
                                />
                            </Col>
						</Row>
						<Row>
							<h4 className="gs-tag blue">{i18n.t<string>("validity")}</h4>
						</Row>
                        <Row gutter={20}>
                            <Col span={12}>
                                <Form.Item
                                    name="initialDate"
                                    label={i18n.t<string>("from")}
                                    rules={[
                                        { required: true, message: i18n.t<string>("required_field") },
                                    ]}
                                >
                                    <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="endDate"
                                    label={i18n.t<string>("until")}
                                    rules={[
                                        { required: true, message: i18n.t<string>("required_field") },
                                    ]}
                                >
                                    <DatePicker className="gs-date-picker" locale={languages[i18n.language]} format={'DD/MM/YYYY'} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={20}>
                            <Col span={24}>
                                <Form.Item
                                    name="currency"
                                    label={i18n.t<string>("conversion_currency")}
                                    rules={[{ required: true, message: i18n.t<string>("required_field") }]}
                                >
                                    <Select
                                        id="input-currency"
                                        options={allCurrencies}
                                        defaultValue={currencyDefault}
                                        placeholder={i18n.t<string>('select')}
                                        {...selectProps}
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
						</Row>
						<Row>
							<Col span={24}>
								<Form.Item
									name="projectionCopyType"
									label={i18n.t<string>("copy_type")}
									rules={[{ required: true, message: i18n.t<string>("required_field") }]}
								>
									<Select
										id="projectionCopyType"
										options={copyTypeOptions}
										defaultValue={0}
										placeholder={i18n.t<string>('select')}
										{...selectProps}
									>
									</Select>
								</Form.Item>
							</Col>
						</Row>
						<Row className="indexer-grouper">
							<Row>
								<Col>
									<h4 className="gs-tag blue">
										{i18n.t("indexer")}
									</h4>
								</Col>
							</Row>
							<Row gutter={5}>
								<Col>
									<Form.Item
										name="isIndexerGrouper"
										initialValue={true}
										valuePropName="checked"
									>
										<Checkbox>
											{i18n.t("indexer_agrouper")}
										</Checkbox>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={10}>
								<Col span={12}>
									<Form.Item
										label={i18n.t("projection_grouper.indexer_type")}
										name="indexerType"
									>
										<Select
											disabled={isIndexerGrouper}
											options={indexerTypeList}
										/>
									</Form.Item>
								</Col>
								<Col span={12}>
									<Form.Item
										label={i18n.t("indexer")}
										name="indexerId"
									>
										<Select
											disabled={isIndexerGrouper}
											options={indexerList}
										/>
									</Form.Item>
								</Col>
							</Row>
						</Row>
                    </div>
                </Form>
            </div>
            <div className="formula-container">
                <FormulaContent
                    formula={formula}
                    setFormula={setFormula}
                    plainFormula={plainFormula}
                    setPlainFormula={setPlainFormula}
                    accountingList={accountingList}
                    projectionsOptions={projectionsOptions}
                    isEditingProjection={isEditingProjection}
                    fieldList={fieldList}
                />
            </div>
        </div>
    );
}